import React from "react"
import PropTypes from "prop-types"
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Header from "./header"
import "./layout.css"
import Footer from './footer'


const useStyles = makeStyles(theme => ({
  root: {
    margin: `0 auto`,
    maxWidth: '100%',
    //padding: `0px 1.0875rem 1.45rem`,
    paddingTop: 0,
    position: "relative",
    minHeight: "85vh",
    paddingBottom: "15vh",
    '@media (max-width: 813px)': {
      paddingBottom: "0vh"
    },
  },
  
}));



const LayoutHome = ({ children }) => {
  const classes = useStyles(); 
  return (
    <MuiThemeProvider>
      <Header />
      <div className={classes.root}>
        <main>{children}</main>

      </div>
      <Footer/>
    </MuiThemeProvider>
  )
}

LayoutHome.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutHome